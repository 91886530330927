import React, { useEffect, useMemo, useRef } from 'react';
import { LinkArrow } from '../link-arrow';
import gsap from 'gsap';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';

gsap.registerPlugin(MorphSVGPlugin);

const ProductSubtitle = ({ children }) => (
	<p className='product-view__subtitle'>{ children }</p>
);

const ProductSectionTitle = ({ children }) => (
	<h2 className='product-view__section-title'>{ children }</h2>
);

const Product = ({ children }) => (
	<div className='product-view__product'>{ children }</div>
);

const ProductName = ({ children }) => (
	<p className='product-view__name'>{ children }</p>
);

const ProductLink = ({ to, label }) => (
	<a href={to} className='product-view__link' target='_blank' rel='noopener noreferrer'>{ label }<LinkArrow/></a>
);

const ProductShowAll = ({ children, onClick, showAll }) => {
	const plusRef = useRef<SVGPathElement>(null);
	const minusRef = useRef<SVGPathElement>(null);
	const animationDuration = .3;
	const timeline = useMemo(() => gsap.timeline({paused: true}), []);

	useEffect(() => {
		if (plusRef.current && minusRef.current) {
			timeline.to(plusRef.current, { morphSVG: {shape: minusRef.current}, duration: animationDuration });
		}
	}, [timeline, plusRef, minusRef]);

	useEffect(() => {
		showAll ? timeline.play() : timeline.reverse();
	}, [showAll]);

	return (
		<button className='product-view__show-more' onClick={ onClick }>
			<svg className='product-view__icon' width='16' height='16'>
				<path ref={plusRef} className='product-view__icon-plus' d='M4 8, L12 8, M8 4, L8 12' stroke='#FFF' strokeWidth='2'></path>
				<path ref={minusRef} className='product-view__icon-minus' d='M4 8 L8 8, L12 8' stroke='#FFF' strokeWidth='2'></path>
			</svg>
			<span className='product-view__show-more-text'>{ children }</span>
		</button>
	);
};

const ProductImageContainer = ({ children }) => (
	<figure className='product-view__image-container'>{children}</figure>
);

const ProductTextContainer = ({ children }) => (
	<div className='product-view__text-container'>{children}</div>
);

export {
	Product,
	ProductName,
	ProductLink,
	ProductImageContainer,
	ProductSectionTitle,
	ProductShowAll,
	ProductSubtitle,
	ProductTextContainer,
};
