import React from 'react';
import clsx from 'clsx';

const C10Title = ({ children }) => (
	<h3 className='seg__title'>{children}</h3>
);

const C10SegBG = ({ color: { color }, isHovered }) => (
	<div className={clsx('seg__bg', {' seg__bg--hovered': isHovered })} style={{ background: color }}/>
);

const C10SegTransition = ({ headline, image, text1, text2 }) => (
	<div className='segment-transition'>
		<section className='seg'>
			<div className='segment-transition__content'>
				<C10Title>{headline}</C10Title>
				<C10TextContainer>
					<p className='segment-transition__big-text segment-transition__big-text--first start1'>
						{text1}
					</p>
					<p className='segment-transition__big-text segment-transition__big-text--second start2'>
						{text2}
					</p>
				</C10TextContainer>
				<div className='segment-transition__image-container'>
					<div className='segment-transition__image-inner'>
						<img className='segment-transition__image segment-transitio__image--static' src={image + '/m/'} />
					</div>
				</div>
			</div>
			<div className='seg__target-container'>
				<div className='absolute seg__target-animated target1'>COMBI</div>
				<div className='absolute seg__target-animated target2'>BAKE</div>
			</div>
		</section> 
	</div>
);

const C10BigText = ({ children, hovered, onHover, onHoverExit }) => (
	<div
		onMouseEnter={onHover}
		onMouseLeave={onHoverExit}
		className={clsx('seg__big-text', { 'seg__big-text--hovered': hovered })}
	>
		{children}
	</div>
);

const C10TextContainer = ({ children }) => (
	<div className='seg__text-container'>
		{children}
	</div>
);

export {
	C10BigText,
	C10Title,
	C10TextContainer,
	C10SegTransition,
	C10SegBG,
};
