import React, { Suspense, useEffect, useRef, useState } from 'react';
import {  useFrame, GroupProps } from '@react-three/fiber';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { CombiModel } from '../combi-model';

export const ObjModel = ({ progress }) => {
	const startRotation = 0;
	const [scale, setScale] = useState(1);
	const pivotRef = useRef<GroupProps>();
	const { width } = useWindowSize();

	useFrame(() => {
		if (pivotRef.current !== undefined) {
			return pivotRef.current.rotation.y = (progress * 2 * Math.PI) * -1 - startRotation;
		}
	});

	useEffect(() => {
		width < 1024 ? setScale(.7) : setScale(1);
	}, [width]);

	return (
		<group scale={scale} ref={pivotRef} position={[0, -1.2, 0]}>
			<Suspense fallback={null}>
				<CombiModel />
			</Suspense>
		</group>
	);
};
