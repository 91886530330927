import { Link } from 'gatsby';
import React from 'react';
import { C2TwoOffsetMediaProps } from './types';
import { LinkArrow } from '../link-arrow';
import StoryBlokImage from '../storyblok-image';
import clsx from 'clsx';

export const C2TwoOffsetMedia = ({ image_one, image_two, headline, text, link_display_text, link }: C2TwoOffsetMediaProps) => {
	return (
		<section className='offset-media'>
			<div className='offset-media__container'>
				{image_one?.filename && <StoryBlokImage className='offset-media__image offset-media__image--square' filename={image_one.filename} alt={image_one.alt} maxWidth={600} maxHeight={600} />}
				<div className='col-span-5'>
					{headline && <p className='offset-media__headline'>{headline}</p>}
					{text && <p className={clsx('text-16 font-light', {'mb-24': link.url})}>{text}</p>}
					{link && link_display_text && link.linktype === 'url' && 
						<a  className='offset-media__link' href={link.url} target='_blank' rel='noreferrer'>{link_display_text}<LinkArrow /></a>
					}
					{link && link_display_text && link.linktype === 'internal' &&
						<Link className='offset-media__link' to={`/${link.cached_url}`}>{link_display_text}<LinkArrow /></Link>
					}
				</div>
			</div>
			<div className='col-span-4 md:col-start-7 md:col-span-6'>
				{image_two?.filename && <StoryBlokImage className='offset-media__image offset-media__image--four-three' filename={image_two.filename} alt={image_two.alt} maxWidth={800} maxHeight={600} />}
			</div>
		</section>
	);
};
