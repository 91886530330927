import React from 'react';
import { richTextResolver } from '../../utils';

const C15TextCentered = ({ richtext }) =>  (
	<section className='text-centered'>
		<div className='text-centered__content' dangerouslySetInnerHTML={{__html: richTextResolver(richtext)}} />
	</section>
);

export default C15TextCentered;
