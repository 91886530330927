import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import StoryblokClient from 'storyblok-js-client';
import { Richtext } from 'storyblok-js-client/types';

const accessToken = process.env.NODE_ENV === 'development'
	? process.env.STORYBLOK_PREVIEW_ACCESS_TOKEN
	: process.env.STORYBLOK_PUBLIC_ACCESS_TOKEN;
const Storyblok = new StoryblokClient({ accessToken });

export const navbarOffset = 16;
export const navbarHeight = 32;
gsap.registerPlugin(ScrollTrigger);

export const createInvertedNavbarScrollTrigger = (trigger: HTMLDivElement, endOfSection: number) => {
	const nav = document.querySelector('.navigation');
	return ScrollTrigger.create({
		trigger: trigger,
		start: -navbarOffset,
		end: () => `+=${endOfSection - navbarOffset - navbarHeight / 2}`,
		onEnter: () => nav?.classList.add('navigation--inverted'),
		onEnterBack: () => nav?.classList.add('navigation--inverted'),
		onLeave: () => nav?.classList.remove('navigation--inverted'),
		onLeaveBack: () => nav?.classList.remove('navigation--inverted'),
	});
};

export enum MediaType {
	Video = 'video',
	Image = 'image',
}

const getExtensionFromFilename = (filename: string) => {
	return filename.split('.').pop();
};

const getMediaTypeFromExtension = (extension: string) => {
	switch(extension) {
	case '3gp':
	case '3gpp':
	case 'asf':
	case 'asx':
	case 'avi':
	case 'flv':
	case 'mng':
	case 'mpeg':
	case 'mpg':
	case 'm4v':
	case 'mov':
	case 'mp4':
	case 'webm':
	case 'wmv':
		return MediaType.Video;
	case 'bmp':
	case 'gif':
	case 'ico':
	case 'jng':
	case 'jpg':
	case 'jpeg':
	case 'png':
	case 'svg':
	case 'tif':
	case 'tiff':
	case 'webp':
		return MediaType.Image;
	
	default:
		console.warn(`Could not find a media type that matches extension: '${extension}`);
	}
};

export const getMediaTypeFromFilename = (filename: string) => {
	return getMediaTypeFromExtension(getExtensionFromFilename(filename));
};

export const isBrowser = typeof window !== 'undefined';

export const richTextResolver = (body: Richtext) => {
	const html = Storyblok.richTextResolver.render(body);
	return html;
};
