import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { getMediaTypeFromFilename, MediaType } from '../../utils';
import { C9FullscreenMediaProps } from './types';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import StoryBlokImage from '../storyblok-image';
import { useLayoutDispatch } from '../../context/layout';
import clsx from 'clsx';

gsap.registerPlugin(ScrollTrigger);

export const C9FullscreenMedia = ({ media, white_text }: C9FullscreenMediaProps) => {
	const mediaType = getMediaTypeFromFilename(media.filename);
	const videoRef = useRef<HTMLVideoElement>(null);
	const triggerRef = useRef<HTMLDivElement>(null);
	const figureRef = useRef<HTMLDivElement>(null);
	const [canPlay, setCanPlay] = useState(false);
	const [isPlaying, setPlaying] = useState(false);
	const { dispatch } = useLayoutDispatch();

	const playVideo = () => {
		if (videoRef.current && videoRef.current.paused && !isPlaying) {
			const promise = videoRef.current.play();
			promise.then(() => {
				setPlaying(true);
			});
		}
	};

	const pauseVideo = () => {
		if(videoRef.current && !videoRef.current.paused && isPlaying) {
			videoRef.current?.pause();
			setPlaying(false);
		}
	};

	useLayoutEffect(() => {
		if(canPlay && videoRef.current !== null) {
			const scrollTrigger = ScrollTrigger.create({
				trigger: triggerRef.current,
				start: 'top 67%',
				end: 'bottom 33%',
				onEnter: () => playVideo(),
				onEnterBack: () => playVideo(),
				onLeave: () => pauseVideo(),
				onLeaveBack: () => pauseVideo(),
			});
			return () => { 
				pauseVideo();
				scrollTrigger.kill();
			};
		}
	}, [canPlay]);

	useEffect(() => {
		if(videoRef.current !== null) {
			const toggleCanPlay = () => setCanPlay(true);
			videoRef.current?.addEventListener('canplay', toggleCanPlay);
			return () => videoRef.current?.removeEventListener('canplay', toggleCanPlay);
		}
	}, []);

	useEffect(() => {
		if(figureRef.current !== null && white_text) {
			const scrollTrigger = ScrollTrigger.create({
				trigger: figureRef.current,
				start: 'top 16px',
				end: 'bottom 86px',
				onEnter: () => dispatch({ type: 'invert' }),
				onLeave: () => dispatch({ type: 'reset' }),
				onEnterBack: () => dispatch({ type: 'invert' }),
				onLeaveBack: () => dispatch({ type: 'reset' }),
			});
			return () => scrollTrigger.kill();
		}
	}, []);

	return (
		<section className={clsx('fullscreen-media', {'hidden md:block': mediaType === MediaType.Video})} ref={triggerRef}>
			<figure ref={figureRef}>
				{mediaType === MediaType.Image && <StoryBlokImage className='fullscreen-media__image' filename={media.filename} alt={media.alt} maxWidth={1600} />}
				{mediaType === MediaType.Video && <video className='fullscreen-media__video' ref={videoRef} src={media.filename} autoPlay muted loop />}
			</figure>
		</section>
	);
};
