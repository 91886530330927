import React from 'react';
import { Canvas } from '@react-three/fiber';
import { ObjModel } from '../gltf-wrapper';
import { SceneLighting } from '../scene-lighting';


export const CustomCanvas = ({ progress }) => {
	return (
		<Canvas>
			<ObjModel progress={progress} />
			<SceneLighting />
		</Canvas>
	);
};
