/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import * as THREE from 'three';

export const CombiModel = (props) => {
	const group = useRef();
	const { nodes, materials } = useGLTF('/combi-compressed.gltf');

	const menuHUDTexture = useTexture('/GlassMENU-10tray_Lowres.png');
	menuHUDTexture.flipY = false;
	const glassTexture = useTexture('/GlassPattern-10tray_Lowres.png');
	glassTexture.flipY = false;
	const surfaceTexture = useTexture('/Anisotropie_Lin_Tiled_A3D_8bit1024.png');
	surfaceTexture.flipY = false;
	surfaceTexture.wrapS = surfaceTexture.wrapT = THREE.MirroredRepeatWrapping;
	surfaceTexture.repeat.set(1, 1);
	
	return (
		<group ref={group} {...props} dispose={null} scale={.03} position={[-.45, 0, 0]}>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes['103355_110'].geometry}
				material={materials['Brushed  Metal 01']}
				position={[13.95, 47.05, 13.75]}
			>
				<meshPhysicalMaterial map={surfaceTexture} roughness={.8} clearcoat={1} clearcoatRoughness={0.13} metalness={1}/>
			</mesh>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes['104277_Led_assembly_106'].geometry}
				material={materials.Light}
				position={[-0.22, 35, 44.44]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Black.geometry}
				material={materials.PlasticBlack}
				position={[17.17, 45.53, 15.2]}
			>
				<meshPhysicalMaterial color='#171717' roughness={.9} clearcoat={.03} clearcoatRoughness={0.1} metalness={.2}/>
			</mesh>	
			<group position={[46.1, 58, 44.65]}>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes['C1(1)'].geometry}
					material={materials.GlassMenu}
					position={[0, -23.15, 0]}
				>
					<meshPhysicalMaterial receiveShadow metalness={0.5} roughness={0.1} map={menuHUDTexture}></meshPhysicalMaterial>
				</mesh>
			</group>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Chamfer3.geometry}
				material={materials.GlassPrint}
			>
				<meshPhysicalMaterial map={glassTexture} metalness={0} roughness={0} transmission={1}></meshPhysicalMaterial>
			</mesh>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes['103504_4_mm_glass_1_10'].geometry}
				material={materials.Glass}
				position={[0, 46.5, 40.65]}
			>
				<meshPhysicalMaterial map={glassTexture} metalness={0} roughness={0} transmission={1}></meshPhysicalMaterial>
			</mesh>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes['32000037'].geometry}
				material={materials.ScrewsMetal}
				position={[15.25, 0, 0]}
			>
				<meshPhysicalMaterial map={surfaceTexture} roughness={.8} clearcoat={1} clearcoatRoughness={0.13} metalness={1}/>
			</mesh>
		</group>
	);
};

useGLTF.preload('/one-combi_fewer-meshes.gltf');
