import clsx from 'clsx';
import React from 'react';
import StoryBlokImage from '../storyblok-image';
import { C3MediaTextProps } from './types';

const C3Video = ({ filename }) => (
	<div className='media-text__video-container'>
		<video className='media-text__video' playsInline autoPlay loop muted src={filename} />
	</div>
);

export const C3OneMediaText = ({title, text, image, video, media_right}: C3MediaTextProps) => {

	return (
		<section className='media-text'>
			<div className={clsx('col-span-4 md:col-span-6 mb-48 md:mb-0', {'md:order-1 md:col-start-7': media_right})}>
				{!video?.filename && image?.filename && <StoryBlokImage className='object-cover aspect-four-three' filename={image.filename} alt={image.alt} maxWidth={800} maxHeight={600} />}
				{video?.filename && <C3Video filename={video.filename} />}
			</div>
			<div className={clsx('col-span-4 flex flex-col justify-center', {'md:col-start-8': !media_right})}>
				{title && <p className='media-text__title'>{title}</p>}
				{text && <p className='font-light text-16' >{text}</p>}
			</div>
			<div></div>
		</section>
	);
};
