import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import TransitionLink from 'gatsby-plugin-transition-link';
import gsap from 'gsap';
import {
	C10BigText,
	C10TextContainer,
	C10Title,
	C10SegBG,
} from './';
import { ContainerValuesType } from './types';
import StoryBlokImage from '../storyblok-image';

const C10Seg = (props) => {
	const {
		headline,
		image: {
			filename: baseImageFilename,
			alt: baseImageAlt,
		},
		content,
	} = props;

	const [hovered, setHovered] = useState('');
	const imageContainerRef = useRef<HTMLDivElement>(null);

	const getElementValues = (element: HTMLElement | null): ContainerValuesType => {
		return {
			posY: element ? element.getBoundingClientRect().top : 0,
			posX: element ? element.getBoundingClientRect().left : 0,
			offsetWidth: element ? element.offsetWidth : 0,
			offsetHeight: element ? element.offsetHeight : 0,
		};
	};

	const cloneHeroImage = (image: HTMLElement) => {
		const clone = image.cloneNode();
		if (image.parentNode) {
			image.parentNode.insertBefore(clone, image.nextSibling);
		}
	};

	const heroAnimation = (node: HTMLElement) => {
		const entryNodeRoot = gsap.utils.selector(node);
		const [heroTitle] = entryNodeRoot('.brand-hero__title');
		const [heroImage] = entryNodeRoot('.brand-hero__image');
		const {
			posX: titlePosX,
			offsetWidth: titleWidth,
		} = getElementValues(heroTitle);
		const {
			posX: imagePosX,
			posY: imagePosY,
			offsetWidth: imageWidth,
			offsetHeight: imageHeight,
		} = getElementValues(imageContainerRef.current);

		if (heroImage) {
			cloneHeroImage(heroImage);
			const [initialHeroImage, clonedHeroImage] = entryNodeRoot('.brand-hero__image');

			if (clonedHeroImage) {
				gsap.from(heroTitle, {
					duration: 1,
					x: -(titlePosX + titleWidth),
				});

				gsap.set(initialHeroImage, {
					autoAlpha: 0,
				});

				gsap.from(clonedHeroImage, {
					duration: 1,
					ease: 'power3.out',
					x: imagePosX,
					y: imagePosY,
					width: imageWidth,
					height: imageHeight,
					onComplete: () => {
						gsap.set(initialHeroImage, {
							autoAlpha: 1,
						});
						clonedHeroImage.remove();
					},
				});
			}
		}
	};

	return (<>
		<section className='seg'>
			{content.map((bg) => <C10SegBG key={`bg-${bg._uid}`} color={bg.color} isHovered={hovered === bg._uid} />)}
			<div className='seg__content'>
				<C10Title>{headline}</C10Title>
				<C10TextContainer>
					{content.map((element) =>  (
						<C10BigText
							key={`content-${element._uid}`}
							onHover={() => setHovered(element._uid)}
							onHoverExit={() => setHovered('')}
							hovered={hovered === element._uid}
						>
							<TransitionLink
								to={element.url === 'story' ? `${element.url.cached_url.charAt(0) === '/' ? '' : '/'}${element.url.cached_url}` : element.url.cached_url}
								exit={{
									trigger: ({ node }) => gsap.set(node, { autoAlpha: 0 }),
								}}
								entry={{
									trigger: ({ node }) => heroAnimation(node),
								}}
							>
								{element.label}
							</TransitionLink>
						</C10BigText>
					))}
					<div className='seg__image-container' ref={imageContainerRef}>
						<div className='seg__image-inner'>
							<StoryBlokImage className='seg__image seg__image--static' filename={baseImageFilename} alt={baseImageAlt} maxWidth={800} />
							{content.map((element) => (
								<StoryBlokImage
									key={`image-${element._uid}`}
									className={clsx('seg__image', {
										'seg__image--active': hovered === element._uid,
									})}
									filename={element.image?.filename}
									alt={element.image?.alt}
									maxWidth={800}
								/>
							))}
						</div>
					</div>
				</C10TextContainer>
			</div>
		</section>
	</>);
};

export default C10Seg;
