import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { C5ObjectRotatorProps } from './types';
import { CustomCanvas } from '../three-d-experience/custom-canvas';

gsap.registerPlugin(ScrollTrigger);

export const C5ObjectRotator = ({ text_blocks }: C5ObjectRotatorProps) => {
	const textBlockHeight = 800;
	const canvas = useRef(null);
	const [progress, setProgress] = useState(0);
	const textBlocksRef = useRef(new Array(text_blocks?.length || 0));

	const animateColorBlack = (element) => {
		gsap.to(element, {
			duration: .4,
			color: '#000000',
		});
	};
	const animateColorGrey = (element) => {
		gsap.to(element, {
			duration: .4,
			color: '#888888',
		});
	};

	useEffect(() => {
		if(textBlocksRef.current.length) {
			const animations: ScrollTrigger[] = [];

			textBlocksRef.current.forEach(el => {
				const animation = ScrollTrigger.create({
					trigger: el,
					start: 'top 33%',
					end: 'bottom 33%',
					onEnter: () => animateColorBlack(el),
					onEnterBack: () => animateColorBlack(el),
					onLeave: () => animateColorGrey(el),
					onLeaveBack: () => animateColorGrey(el),
				});
				animations.push(animation);
			});
			return () => {
				animations.forEach(anim => anim.kill());

				textBlocksRef.current.forEach(el => {
					if (el !== null) {
						gsap.set(el, {
							clearProps: true,
						});
					}
				});
			};
		}
	}, [textBlocksRef]);

	useEffect(() => {
		if(canvas.current && text_blocks !== undefined ) {
			gsap.set(canvas.current, {
				top: 0,
				right: 0,
			});
			const progressTrigger = ScrollTrigger.create({
				trigger: canvas.current,
				start: 'top',
				end: `+=${(text_blocks?.length - 1) * textBlockHeight}px`,
				pin: true,
				onUpdate: self => setProgress(self.progress),
			});
			return () => progressTrigger.kill();
		}
	}, [canvas]);

	return (
		<section className='object-rotator' style={{ height: text_blocks ? text_blocks.length * textBlockHeight : textBlockHeight }}>
			<div className='object-rotator__content'>
				{text_blocks && text_blocks.map((block, i) => (
					<div ref={el => textBlocksRef.current[i] = el} className='object-rotator__text-block' style={{ height: textBlockHeight }} key={block.title || 'object-rotator__text-block'+ i}>
						{block.title && <h2 className='object-rotator__title'>{block.title}</h2>}
						{block.description && <p className='object-rotator__description'>{block.description}</p>}
					</div>
				))}
			</div>
			<div ref={canvas} className='object-rotator__canvas' style={{ height: textBlockHeight }}>
				<CustomCanvas progress={progress} />
			</div>
		</section>
	);
};

