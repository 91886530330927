import React from 'react';
import StoryBlokImage from '../storyblok-image';
import { C4LogoProps } from './types';

export const C4Logo = ({ logos }: C4LogoProps) => {
	return (
		<section className='logos'>
			{logos && logos.map((logo, i) =>
				<div key={logo.filename + i} className='logos__logo-container'>
					<StoryBlokImage className='logos__logo' filename={logo.filename} alt={logo.alt} />
				</div>,
			)}
		</section>
	);
};
