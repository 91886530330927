import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { C8SideScrollExperience, C8SideScrollItem, C8SideScrollProps } from './types';
import { useWindowSize } from '../../hooks/useWindowSize';
import StoryBlokImage from '../storyblok-image';

const SideScrollItem = ({ item }: { item: C8SideScrollItem }) => {
	const { title, description, image } = item;
	return (
		<li className={clsx('side-scroll__item', {
			'side-scroll__item--no-figure': !image?.filename,
		})}>
			<p className='side-scroll__item-title'>{title}</p>
			<p className='side-scroll__item-description'>{description}</p>
			{image?.filename && (
				<figure className='side-scroll__item-image'>
					<StoryBlokImage className='object-cover w-full h-full' filename={image.filename} alt={image.alt} maxWidth={600} />
				</figure>
			)}
		</li>
	);
};

const DownloadsItem = ({ title, tagline, files }) => {
	return (
		<li className='side-scroll__item side-scroll__item--downloads'>
			<p className='side-scroll__downloads-title'>{title}</p>
			<div>
				<p className='side-scroll__downloads-tagline'>{tagline}</p>
				<ul className='side-scroll__downloads-files'>
					{files && files.map(file =>
						<li className='side-scroll__downloads-file' key={file.id}>
							<a href={file.filename} target='__blank'>{file.title}</a>
						</li>,
					)}
				</ul>
			</div>
		</li>
	);
};

const DesktopExperience = ({ children, sectionElement, wrapperElement, itemLength }) => {
	const desktopExperienceRef = useRef<HTMLUListElement>(null);
	const itemWidth = 412;
	const margin = 106;
	const padding = 100;
	let scrollTrigger: ScrollTrigger;

	const initScrolltrigger = () => {
		if(sectionElement !== null && wrapperElement !== null ) {
			scrollTrigger = ScrollTrigger.create({
				trigger: sectionElement,
				start: 'top top',
				end: '+=' + (sectionElement?.clientHeight - wrapperElement?.clientHeight - padding),
				pin: wrapperElement,
				animation: gsap.fromTo(desktopExperienceRef.current, {
					x: () => margin,
				}, {
					x: () => -((itemWidth + margin) * (itemLength - 1) - margin),
					ease: 'none',
				}),
				scrub: true,
			});
		}
	};

	useEffect(() => {
		if(sectionElement !== null ) {
			gsap.set(sectionElement, {
				height: wrapperElement?.clientHeight + (itemWidth + margin) * (itemLength - 1),
			});
			initScrolltrigger();
			return () => scrollTrigger?.kill();
		}
	}, [itemLength]);

	useEffect(() => {
		if(sectionElement !== null) {
			return () => {
				scrollTrigger.kill();
				gsap.set(sectionElement, { clearProps: true });
			};
		}
	}, []);

	return (
		<ul ref={desktopExperienceRef} className='side-scroll__item-container'>
			{children}
		</ul>
	);
};

const MobileExperience = ({ children, itemLength, width }) => {
	const mobileExperienceRef = useRef<HTMLUListElement>(null);
	let draggable: Draggable[];
	const mobilePadding = 10;
	let itemWidth = 280;

	const initDraggable = () => {
		draggable = Draggable.create(mobileExperienceRef.current, {
			throwProps: true,
			type: 'x',
			trigger: mobileExperienceRef.current,
			bounds: { minX: -Math.abs((Math.floor(itemWidth) + mobilePadding) * (itemLength - 1)), maxX: 0, minY: 0, maxY: 0 },
			inertia: true,
			snap: { x: gsap.utils.snap(Math.floor(itemWidth) + mobilePadding) },
		});
	};

	useEffect(() => {
		if(mobileExperienceRef.current !== null) {
			gsap.set(mobileExperienceRef.current, { x: 0 });
			const item = mobileExperienceRef.current?.querySelector('.side-scroll__item');
			if (item) {
				itemWidth = item.clientWidth;
				initDraggable();
				return () => draggable?.forEach(instance => instance.kill());
			}
		}
	}, [width, itemLength]);

	return (
		<ul ref={mobileExperienceRef} className='side-scroll__item-container'>
			{children}
		</ul>
	);
};

export const C8SideScroll = ({ tagline, title, experiences = [] }: C8SideScrollProps) => {
	const [isMobile, setIsMobile] = useState(false);
	const { width } = useWindowSize();
	const [currentExperience, setCurrentExperience] = useState<C8SideScrollExperience>();
	const sectionRef = useRef<HTMLElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// Draggable is making the build fail, hence this placement
		gsap.registerPlugin(ScrollTrigger, Draggable, InertiaPlugin, ScrollToPlugin);
	}, []);

	const handleClick = (experience: C8SideScrollExperience) => {
		if (experience !== currentExperience && window !== undefined && sectionRef.current !== null) {
			isMobile ? null : gsap.set(window, { scrollTo: sectionRef.current });
		}
		setCurrentExperience(experience);
	};

	useEffect(() => {
		setCurrentExperience(experiences[0]);
	}, [experiences]);

	useEffect(() => {
		if (width < 768) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [width]);

	const getItemLength = () => {
		const currentExperienceLength = currentExperience !== undefined && currentExperience.items ? currentExperience.items.length : 0;
		return currentExperienceLength + (currentExperience?.downloads ? 1 : 0);
	};

	return (
		<section ref={sectionRef} className='side-scroll'>
			<div ref={wrapperRef} className='side-scroll__wrapper'>
				<div className='side-scroll__navigation'>
					{tagline && <p className='side-scroll__tagline'>{tagline}</p>}
					{title && <p className='side-scroll__title'>{title}</p>}
					<ul>
						{experiences && experiences.map(experience =>
							<li key={experience._uid} className={clsx('side-scroll__experience', { 'side-scroll__experience--active': experience === currentExperience })}>
								<button className='text-left' onClick={() => handleClick(experience)}>{experience.title}</button>
							</li>)}
					</ul>
				</div>
				<div className='side-scroll__scrollable-area'>
					{isMobile ?
						<MobileExperience itemLength={getItemLength()} width={width}>
							{currentExperience?.items?.map(item => <SideScrollItem key={item._uid} item={item} />)}
							{currentExperience?.downloads && 
								<DownloadsItem title={currentExperience.downloads[0].title} tagline={currentExperience.downloads[0].tagline} files={currentExperience.downloads[0].files} />
							}
						</MobileExperience>
						:
						<DesktopExperience sectionElement={sectionRef.current} wrapperElement={wrapperRef.current} itemLength={getItemLength()}>
							{currentExperience?.items?.map(item => <SideScrollItem key={item._uid} item={item} />)}
							{currentExperience?.downloads && 
								<DownloadsItem title={currentExperience.downloads[0].title} tagline={currentExperience.downloads[0].tagline} files={currentExperience.downloads[0].files} />
							}
						</DesktopExperience>
					}
				</div>
			</div>
		</section>
	);
};
