import React from 'react';
import StoryBlokImage from '../storyblok-image';
import { C14TwoMediaTextProps } from './types';
import { LinkArrow } from '../link-arrow';

export const C14TwoMediaText = ({
	image_one,
	image_two,
	text,
	title,
	subtitle,
	document_link,
	document_link_label,
}: C14TwoMediaTextProps) => {

	return (
		<section className='two-media'>
			<div className='col-span-2 mb-48 md:col-span-3 md:mb-0'>
				{image_one?.filename && <StoryBlokImage className='w-full h-full object-cover aspect-six-seven' filename={image_one.filename} alt={image_one.alt} maxWidth={400} />}
			</div>
			<div className='col-span-2 mb-48 md:col-span-3 md:mb-0'>
				{image_two?.filename && <StoryBlokImage className='w-full h-full object-cover aspect-six-seven' filename={image_two.filename} alt={image_two.alt} maxWidth={400} />}
			</div>
			<div className='flex flex-col justify-center col-span-4 md:col-start-8'>
				{subtitle && <p className='mb-16 font-light text-12'>{subtitle}</p>}
				{title && <p className='mb-24 font-light text-24'>{title}</p>}
				{text && <p className='font-light text-16'>{text}</p>}
				{document_link?.filename && (
					<a
						target='_blank'
						rel='noopener noreferrer'
						href={document_link.filename}
						className='mt-24 font-light leading-200 text-12'
					>
						{document_link_label}
						<LinkArrow/>
					</a>
				)}
			</div>
		</section>
	);
};
