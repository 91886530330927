import React, { useEffect, useMemo, useRef, useState } from 'react';
import { C6AccordionProps } from './types';
import gsap from 'gsap';
import { MorphSVGPlugin } from 'gsap/dist/MorphSVGPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { richTextResolver } from '../../utils';
import StoryBlokImage from '../storyblok-image';
import clsx from 'clsx';
import { useWindowSize } from '../../hooks/useWindowSize';

gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);

const AccordionItem = ({ item, onClickHandler, isOpen }) => {
	const { title, description, _uid } = item;
	const itemRef = useRef<HTMLDivElement>(null);
	const plusRef = useRef(null);
	const arrowRef = useRef(null);
	const descriptionRef = useRef<HTMLDivElement>(null);
	const mobileButtonHeight = 60;
	const desktopButtonHeight = 44;
	const [buttonHeight, setButtonHeight ] = useState(desktopButtonHeight);
	const [itemHeight, setItemHeight] = useState(desktopButtonHeight);
	const timeline = useMemo(() => gsap.timeline({paused: true}), []);
	const { width } = useWindowSize();

	const animationDuration = .3;

	const handleClick = () => {
		onClickHandler(_uid);
	};

	useEffect(() => {
		if (itemRef.current) {
			setItemHeight(itemRef.current.scrollHeight);
		}
	}, [descriptionRef.current?.scrollHeight]);

	useEffect(() => {
		if(plusRef.current && arrowRef.current) {
			timeline.to(plusRef.current, { morphSVG: {shape: arrowRef.current}, duration: animationDuration });
		}
	}, [timeline, plusRef, arrowRef]);

	useEffect(() => {
		isOpen ? timeline.play() : timeline.reverse();
		setTimeout(() => {
			ScrollTrigger.refresh();
		}, animationDuration * 1000);
	}, [timeline, isOpen]);

	useEffect(() => {
		width < 1024 ? setButtonHeight(mobileButtonHeight) : setButtonHeight(desktopButtonHeight);
	}, [width]);

	return (
		<div ref={itemRef} className='accordion__item' style={{height: isOpen ? itemHeight : buttonHeight}}>
			<button onClick={handleClick} className='accordion__button'>
				<h3 className='accordion__item-title'>{title}</h3>
				<svg className='accordion__icon' width='16' height='16'>
					<path ref={plusRef} className='accordion__icon-plus' d='M4 8, L12 8, M8 4, L8 12' stroke='#FFF' strokeWidth='2'></path>
					<path ref={arrowRef} className='accordion__icon-arrow' d='M4 9, L8 5, L12 9' stroke='#FFF' strokeWidth='2'></path>
				</svg>
			</button>
			<div ref={descriptionRef} className='accordion__item-description'>
				<div dangerouslySetInnerHTML={{__html: richTextResolver(description)}} />
			</div>
		</div>
	);
};

export const C6Accordion = ({ image, items, subtitle, text, title }: C6AccordionProps) => {
	const [openItems, setOpenItems] = useState<string>('');

	const handleClick = (id: string) => {
		if (openItems === id) {
			setOpenItems(() => '');
		} else {
			setOpenItems(() => id);
		}
	};

	return (
		<section className='accordion'>
			<figure className='accordion__figure'>
				{image?.filename && <StoryBlokImage className={clsx('accordion__accordion-image', {'accordion__accordion-image--open': openItems === ''})} filename={image.filename} alt={image.alt} maxWidth={800} />}
				{items.map(item => (
					<StoryBlokImage className={clsx('accordion__accordion-image', {
						'accordion__accordion-image--open': openItems.includes(item._uid) })}
					alt={item?.image?.alt} 
					filename={item?.image?.filename}
					key={`accordion-image-${item._uid}`}
					maxWidth={800}
					/>
				))}
			</figure>
			<div className='accordion__item-container'>
				<div className='accordion__content-wrapper'>
					{subtitle && <p className='accordion__subtitle'>{subtitle}</p>}
					{title && <h2 className='accordion__title'>{title}</h2>}
					{text && <p className='accordion__text'>{text}</p>}
				</div>
				{items && items.map(textBlock => (
					<AccordionItem key={textBlock._uid} item={textBlock} isOpen={openItems.includes(textBlock._uid)} onClickHandler={handleClick} />
				))}
			</div>
		</section>
	);
};
