import React from 'react';

export const LinkArrow = () => {
	return (
		<svg className='inline-block mx-8' width='8' height='8' stroke='#000000' fill='none'>
			<path strokeWidth='2' d='M0 0, L8 0, L8 8' />
			<path d='M0 8, L8 0' />
		</svg>
	);
};
