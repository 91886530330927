import React from 'react';
import StoryBlokImage from '../storyblok-image';
import { C13ThreeMediaProps } from './types';

export const C13ThreeMedia = ({ image_one, image_two, image_three }: C13ThreeMediaProps) => {
	return (
		<section className='three-media'>
			<div className='col-span-2 md:col-span-3 order-2 md:order-1'>
				{image_one?.filename && <StoryBlokImage className='h-full object-cover w-full aspect-four-three' filename={image_one.filename} alt={image_one.alt} maxWidth={400} />}
			</div>
			<div className='col-span-2 md:col-span-3 order-3 md:order-2'>
				{image_two?.filename && <StoryBlokImage className='h-full object-cover w-full aspect-four-three' filename={image_two.filename} alt={image_two.alt} maxWidth={400} />}
			</div>
			<div className='col-span-4 mb-32 order-1 md:mb-0 md:order-3 md:col-start-7 md:col-span-6'>
				{image_three?.filename && <StoryBlokImage className='h-full object-cover w-full aspect-eight-three' filename={image_three.filename} alt={image_three.alt} maxWidth={800} />}
			</div>
		</section>
	);
};
