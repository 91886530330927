import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useWindowSize } from '../../hooks/useWindowSize';
import { B1BrandVideoTextProps } from './types';
import gsap from 'gsap';
import { createInvertedNavbarScrollTrigger } from '../../utils'; 

export const B1BrandVideoText = ({ media, sentences }: B1BrandVideoTextProps) => {
	const sectionRef = useRef<HTMLDivElement>(null);
	const pinnedContainer = useRef(null);
	const sentencesContainer = useRef(null);
	const amplitude = 0.5;
	const constant = 0.5;
	const { height } = useWindowSize();

	useLayoutEffect(() => {
		if(sectionRef.current) {
			sectionRef.current.style.height = sentences?.length ? sentences.length * 100 + 'vh' : '100vh';
		}
	});

	useEffect(() => {
		let navTrigger;
		if(sectionRef?.current && sentences) {
			navTrigger = createInvertedNavbarScrollTrigger(sectionRef.current, height * sentences.length);
		}
		return () => navTrigger?.kill();
	}, [sectionRef, height]);

	useEffect(() => {
		let animation;
		if(sentences) {
			animation = ScrollTrigger.create({
				trigger: pinnedContainer.current,
				start: 'top top',
				end: () => `+=${height * (sentences.length - 1)}px`,
				onUpdate: self => gsap.set(sentencesContainer.current, {
					opacity: () => amplitude * Math.sin((sentences?.length - 1) * 2 * Math.PI * self.progress) + constant,
				}),
				pin: true,
			});
		}
		const endAnimation = ScrollTrigger.create({
			trigger: pinnedContainer.current,
			start: 'bottom bottom',
			end: 'bottom center',
			animation: gsap.to(sentencesContainer.current, {
				opacity: 1,
			}),
			scrub: true,
		});
		return () => {
			animation?.kill();
			endAnimation.kill();
		};
	}, [pinnedContainer, height]);

	return (
		<section ref={sectionRef} className='brand-video'>
			<div ref={pinnedContainer} className='brand-video__pinned-container'>
				{media && <video className='brand-video__video' src={media.filename} playsInline autoPlay loop muted/>}
			</div>
			<div ref={sentencesContainer} className='brand-video__sentences'>
				{sentences && sentences.map((sentence) => (
					<p key={sentence._uid} className='brand-video__sentence'>
						{sentence.text}
					</p>
				))}
			</div>
		</section>
	);
};
