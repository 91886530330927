import * as React from 'react';
import { ContextProvider } from '../context';
import Layout from '../components/layout';
import DynamicComponent from '../components/dynamic-component/';
import Meta from '../components/meta';
import StorelocatorIFrame from '../components/d1-storelocator/module';

const Page = ({ pageContext }) => {
	const {
		body,
		background_color,
		page_title,
		meta_title,
		meta_description,
		meta_image,
	} = JSON.parse(pageContext.story.content);

	React.useEffect(() => {
		document.documentElement.style.backgroundColor = background_color.color;
	}, []);

	return (
		<ContextProvider>
			<Layout>
				<Meta
					language={pageContext.story.lang}
					title={page_title}
					ogTitle={meta_title}
					ogDescription={meta_description}
					description={meta_description}
					ogImage={meta_image?.filename}
				/>
				{body.map(blok =>
					<DynamicComponent blok={blok} key={blok._uid} />,
				)}
			</Layout>
			<div className='storelocator__footer'>
				<StorelocatorIFrame />
			</div>
		</ContextProvider>
	);
};

export default Page;
