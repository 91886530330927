import React, { useRef } from 'react';
import { C12TextScrollActivatedProps } from './types';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';

gsap.registerPlugin(ScrollTrigger); 

export const C12TextScrollActivated = ({ text }: C12TextScrollActivatedProps) => {
	const textContainer = useRef();
	const textElement = useRef<HTMLHeadingElement>();
	const {width, height} = useWindowSize();

	useEffect(() => {
		ScrollTrigger.create({
			trigger: textElement.current,
			start: 'top 50%',
			end: 'bottom top',
			onUpdate: self => {
				if(textElement.current) {
					gsap.set(textElement.current, {
						translateX: textElement.current.scrollWidth * self.progress * -1,
					});
				}
			},
		});
	}, [textContainer, textElement, width, height]);
	

	return (
		<div className='w-full overflow-x-hidden'>
			<div ref={textContainer} className='text-scroll'>
				{text && <h3 ref={textElement} className='absolute font-light'>{text}</h3>}
			</div>
		</div>
	);
};
