import { Environment, Lightformer } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React, { useRef } from 'react';

export const SceneLighting = () => {
	return (
		<>
			<ambientLight intensity={2}/>
			<Environment files='studio_Render__EricdesignReflection-lowres.hdr' frames={Infinity} path={'/'}>
				<Lightformer form='circle' intensity={1} position={[8, 1, 8]} target={[0, 0, 0]} scale={4}/>
				<MovingSpots />
			</Environment>
		</>
	);
};

const MovingSpots = () => {
	const groupRef = useRef();
	const travelDistanceX = 64;
	const animationSpeed = 12;
	useFrame((state, delta) => {
		(groupRef.current.position.x -= delta * animationSpeed) < -travelDistanceX && (groupRef.current.position.x = travelDistanceX / 2);
	});
	return (
		<group>
			<group ref={groupRef} position={[travelDistanceX / 2, 0, 4]}>
				<Lightformer form='rect' intensity={2} position={[0, .5, 1]} scale={[2, 6, 2]} />
				<Lightformer form='rect' intensity={2} position={[4, .5, 2]} scale={[2, 6, 2]} />
				<Lightformer form='rect' intensity={2} position={[8, .5, 4]} scale={[2, 6, 2]} />
				<Lightformer form='rect' intensity={2} position={[12, .5, 8]} scale={[2, 6, 2]} />
			</group>
		</group>
	);
};
