import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useWindowSize } from '../../hooks/useWindowSize';
import { C11ImageResizeBiggerProps } from './types';
import StoryBlokImage from '../storyblok-image';

gsap.registerPlugin(ScrollTrigger);

export const C11ImageResizeBigger = ({ image }: C11ImageResizeBiggerProps) => {
	const imageRef = useRef<HTMLImageElement>(null);
	const timeline = gsap.timeline({ paused: true });
	const { width } = useWindowSize();

	useEffect(() => {
		if(imageRef.current) {
			const isWindowWiderThanImage = width > imageRef.current.clientWidth;
			timeline.to(imageRef.current, {
				width: '100%',
				duration: 1.7,
				onComplete: () => ScrollTrigger.refresh(),
				ease: 'power4.out',
			});

			const animation = ScrollTrigger.create({
				trigger: imageRef.current,
				start: 'top center',
				end: 'bottom top',
				onEnter: () => isWindowWiderThanImage ? timeline.play() : null,
				onEnterBack: () => isWindowWiderThanImage ? timeline.play() : null,
				onLeaveBack: () => isWindowWiderThanImage ? timeline.reverse() : null,
			});

			return () => {
				animation.kill();
				if (imageRef.current !== null) {
					gsap.set(imageRef?.current, {
						clearProps: true,
					});
				}
			};
		}
	}, [imageRef, width]);

	return (
		<section className='image-resize'>
			{image?.filename && 
				<figure className='image-resize__image' ref={imageRef}>
					<StoryBlokImage className='object-cover h-full mx-auto' alt={image.alt} filename={image.filename} maxWidth={1600} />
				</figure>
			}
		</section>
	);
};
