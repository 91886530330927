import React, { useState } from 'react';
import StoryBlokImage from '../storyblok-image';

import {
	ProductSectionTitle,
	Product,
	ProductName,
	ProductLink,
	ProductImageContainer,
	ProductShowAll,
	ProductSubtitle,
	ProductTextContainer,
} from './';

const ProductView = (props) => {
	const { content, sub_title, title, show_all_products_label, hide_products_label } = props;
	const [showAll, setShowAll] = useState(false);
	const initialNumberOfItems = 3;
	const productImageMaxHeight = 287;

	const handleClick = () => setShowAll(prev => !prev);

	return (
		<section className='product-view'>
			<ProductTextContainer>
				<ProductSubtitle>{sub_title}</ProductSubtitle>
				<ProductSectionTitle>{title}</ProductSectionTitle>
			</ProductTextContainer>
			{content.slice(0, showAll ? content.length : initialNumberOfItems).map(({_uid, product_name, link, link_label, image: { filename, alt }}) => (
				<Product key={_uid}>
					<ProductImageContainer>
						{filename && <StoryBlokImage className='product-view__image' filename={filename} alt={alt} maxHeight={productImageMaxHeight} />}
					</ProductImageContainer>
					<ProductName>{product_name}</ProductName>
					<ProductLink to={link.cached_url} label={link_label} />
				</Product>
			))}
			{content.length > initialNumberOfItems && <ProductShowAll showAll={showAll} onClick={handleClick}>{showAll ? hide_products_label : show_all_products_label}</ProductShowAll>}
		</section>
	);};

export default ProductView;
