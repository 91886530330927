import React from 'react';
import { B1BrandVideoText } from '../b1-brand-video-text';
import { B2BrandHero } from '../b2-brand-hero';
import { C1TextSection } from '../c1-text';
import { C2TwoOffsetMedia } from '../c2-two-offset-media';
import { C3OneMediaText } from '../c3-one-media-text';
import { C4Logo } from '../c4-logo';
import { C5ObjectRotator } from '../c5-object-rotator';
import { C6Accordion } from '../c6-accordion';
import C7ProductView from '../c7-product-view/module';
import { C8SideScroll } from '../c8-side-scroll';
import { C9FullscreenMedia } from '../c9-fullscreen-media';
import C10Seg from '../c10-seg/module';
import { C11ImageResizeBigger } from '../c11-image-resize-bigger';
import { C12TextScrollActivated } from '../c12-text-scroll-activated';
import { C13ThreeMedia } from '../c13-three-media';
import { C14TwoMediaText } from '../c14-two-media-text';
import C15TextCentered from '../c15-text-centered';
const Components = {
	'b1-brand-video-text': B1BrandVideoText,
	'b2-brand-hero': B2BrandHero,
	'c1-text': C1TextSection,
	'c2-two-offset-media': C2TwoOffsetMedia,
	'c3-one-media-text': C3OneMediaText,
	'c4-logo': C4Logo,
	'c5-object-rotator': C5ObjectRotator,
	'c6-accordion': C6Accordion,
	'c7-product-view': C7ProductView,
	'c8-side-scroll': C8SideScroll,
	'c9-fullscreen-media': C9FullscreenMedia,
	'c10-link-segment': C10Seg,
	'c11-image-resize-bigger': C11ImageResizeBigger,
	'c12-text-scroll-activated': C12TextScrollActivated,
	'c13-three-media': C13ThreeMedia,
	'c14-two-media-text': C14TwoMediaText,
	'c15-text-centered': C15TextCentered,
};

const DynamicComponent = ({ blok }) => {
	if (typeof Components[blok.component] !== 'undefined') {
		const Component = Components[blok.component];
		return (<Component {...blok} />);
	}

	return (<p>The component <strong>{blok.component}</strong> has not been created yet.</p>);
};

export default DynamicComponent;