import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { B2BrandHeroProps } from './types';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useWindowSize } from '../../hooks/useWindowSize';
import { createInvertedNavbarScrollTrigger } from '../../utils';
import clsx from 'clsx';

gsap.registerPlugin(ScrollTrigger);

export const B2BrandHero = ({title, image, video}: B2BrandHeroProps) => {
	const sectionRef = useRef<HTMLDivElement>(null);
	const titleWrapperRef = useRef<HTMLDivElement>(null);
	const titleRef = useRef<HTMLHeadingElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);
	const [canPlay, setCanPlay] = useState(false);
	const [isPlaying, setPlaying] = useState(false);
	const {height, width} = useWindowSize();
	const actualFontHeightToElementHeightRatio = .7;

	useEffect(() => {
		if(sectionRef.current ) {
			const navTrigger = createInvertedNavbarScrollTrigger(sectionRef.current, height * (video?.filename ? 2 : 1));
			return () => navTrigger.kill();
		}
	}, [sectionRef, height]);

	useEffect(() => {
		if(sectionRef.current &&  titleRef.current) {
			const titleScrollTrigger = ScrollTrigger.create({
				trigger: sectionRef.current,
				start: 'top top',
				end: '+=' + ((sectionRef.current.clientHeight * (video?.filename ? .75 : .5) - titleRef.current.clientHeight / 2 * actualFontHeightToElementHeightRatio) - 24),
				pin: titleWrapperRef.current,
			});
			return () => titleScrollTrigger.kill();
		}
	}, [sectionRef, titleWrapperRef, titleRef, height, width]);

	useEffect(() => {
		const imageScrollTrigger = ScrollTrigger.create({
			trigger: sectionRef.current,
			start: 'top top',
			end: 'bottom bottom',
			pin: imageRef.current,
		});
		return () => imageScrollTrigger.kill();
	}, [sectionRef, imageRef]);

	const playVideo = () => {
		if (videoRef.current && videoRef.current.paused && !isPlaying) {
			const promise = videoRef.current.play();
			promise.then(() => {
				setPlaying(true);
			});
		}
	};

	const pauseVideo = () => {
		if(videoRef.current && !videoRef.current.paused && isPlaying) {
			videoRef.current?.pause();
			setPlaying(false);
		}
	};

	useEffect(() => {

		if( videoRef.current !== null) {
			const videoScrollTrigger = ScrollTrigger.create({
				trigger: videoRef.current,
				start: 'top bottom',
				end: 'bottom top',
				onEnter: () => playVideo(),
				onEnterBack: () => playVideo(),
				onLeave: () => pauseVideo(),
				onLeaveBack: () => pauseVideo(),
			});
			return () => videoScrollTrigger.kill();
		}
	}, [canPlay]);

	useEffect(() => {
		if(videoRef.current !== null) {
			const toggleCanPlay = () => setCanPlay(true);
			videoRef.current?.addEventListener('canplay', toggleCanPlay);
			return () => videoRef.current?.removeEventListener('canplay', toggleCanPlay);
		}
	}, []);

	return (
		<section ref={sectionRef} className={clsx('brand-hero', {'brand-hero--no-video': !video?.filename})} >
			{title && 
				<div ref={titleWrapperRef} className='brand-hero__title-wrapper'>
					<h1 ref={titleRef} className='brand-hero__title'>
						<span className='brand-hero__title-snippet'>
							{title}
						</span>
					</h1>
				</div>
			}
			{image && <img ref={imageRef} className='brand-hero__image' src={image.filename + '/m/1920x0'} alt={image.alt}/>}
			{video && <video ref={videoRef} className='brand-hero__video' src={video.filename} muted loop />}
		</section>
	);
};
