import React from 'react';
import { C1TextProps } from './types';

export const C1TextSection = ({ text, title }: C1TextProps) => {
	return (
		<section className='text-section'>
			<div className='col-span-4 md:col-start-2 md:col-span-10'>
				{title && <p className='text-section__title'>{title}</p>}
				<p className='font-light text-24' >{text}</p>
			</div>
		</section>
	);
};