import React from 'react';
import { StoryBlokImageProps } from './types';

const StoryBlokImage = ({filename, alt, maxWidth, maxHeight, ...rest}: StoryBlokImageProps) => {
	const imageMaxWidth = maxWidth ? maxWidth : 0;
	const imageMaxHeight = maxHeight ? maxHeight: 0;
	if(!filename) return null;

	return (
		<img {...rest} src={`${filename}/m/${imageMaxWidth}x${imageMaxHeight}/smart`} alt={alt}/>
	);
};

export default StoryBlokImage;
